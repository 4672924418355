// src/components/MeetTheBaker/SocialMediaSection.js

import React from 'react';
import '../../styles/MeetTheBaker/SocialMediaSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';

const SocialMediaSection = () => {
  const socialPlatforms = [
    {
      name: 'Instagram',
      icon: faInstagram,
      url: 'https://www.instagram.com/deydey_bakes',
      color: '#E1306C',
    }
  ];

  return (
    <section className="social-media-section" id="social-media">
      <h2>Connect With Us</h2>
      <div className="social-icons animate-on-scroll" data-animation="fade-in-right">
        {socialPlatforms.map((platform, index) => (
          <a
            key={index}
            href={platform.url}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={platform.name}
            title={platform.name}
            style={{ '--icon-color': platform.color }}
            className="social-icon-link"
          >
            <FontAwesomeIcon icon={platform.icon} size="2x" />
          </a>
        ))}
      </div>
      <p className="social-message">Follow us on social media for the latest updates and exclusive offers, or contact us at info@deydeybakesllc.com </p>
    </section>
  );
};

export default SocialMediaSection;