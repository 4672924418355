import strawberryInfusedImage from '../assets/images/thc/strawberry-infused.jpeg';
import lemonInfusedImage from '../assets/images/thc/lemon-infused.jpeg'
import champagneInfusedImage from '../assets/images/thc/champagne-infused.jpeg'
import chocolateInfusedImage from '../assets/images/thc/chocolate-infused.jpeg'
import chocolateRasInfusedImage from '../assets/images/thc/Chocolaterasberry-infused.jpg'
import redvelvetInfusedImage from '../assets/images/thc/redvelvet-infused.jpeg'
import vanillastrawInfusedImage from '../assets/images/thc/vanillastraw-infused.jpeg'
import varietyPack from '../assets/images/thc/variety-infused.jpeg'

// src/data/thcProducts.js
export const thcProducts = [
    {
      id: 1,
      category: "THC-Cupcakes",
      name: 'Lemon THC Cupcakes',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes Lemon w/Lemon Curd and white chocolate.',
      price: 60.00,
      image: lemonInfusedImage,
      quantity: 1,
    },
    {
      id: 2,
      category: "THC-Cupcakes",
      name: 'Pink Champagne THC Cupcakes',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes Pink Champagne and white chocolate',
      price: 60.00,
      image: champagneInfusedImage,
      quantity: 1,
    },
    {
      id: 3,
      category: "THC-Cupcakes",
      name: 'Vanilla THC Cupcakes',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes Vanilla w/Strawberry Ganache and white chocolate',
      price: 60.00,
      image: vanillastrawInfusedImage,
      quantity: 1,
    },
    {
      id: 4,
      category: "THC-Cupcakes",
      name: 'Chocolate and Raspberry THC Cupcakes',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes Chocolate w/Raspberry Jam and dark chocolate',
      price: 60.00,
      image: chocolateRasInfusedImage,
      quantity: 1,
    },
    {
      id: 5,
      category: "THC-Cupcakes",
      name: 'THC Cupcakes Variety Pack',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes “Variety Pack',
      price: 60.00,
      image: varietyPack,
      quantity: 1,
    },
    {
      id: 6,
      category: "THC-Cupcakes",
      name: 'Red Velvet THC Cupcakes',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes Red Velvet and dark chocolate',
      price: 60.00,
      image: redvelvetInfusedImage,
      quantity: 1,
    },
    {
      id: 7,
      category: "THC-Cupcakes",
      name: 'Chocolate THC Cupcakes with Ganache',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes Chocolate w/Chocolate Ganache and dark chocolate',
      price: 60.00,
      image: chocolateInfusedImage,
      quantity: 1,
    },
    {
      id: 8,
      category: "THC-Cupcakes",
      name: 'Strawberry THC Cupcakes',
      description: '1/2 Dozen Mini-size Infused ‘Edible’ Cupcakes Strawberry and white chocolate',
      price: 60.00,
      image: strawberryInfusedImage,
      quantity: 1,
    },
    
    // Add more products as needed
  ];