// src/components/FAQs/FAQs.js

import React, { useState } from "react";
import styles from "../styles/FAQs/FAQs.module.css";
import faqData from "../data/faqData";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Footer from '../components/Footer';
const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState({});

  const toggleFAQ = (categoryIndex, faqIndex) => {
    setActiveIndex((prevState) => {
      const isActive =
        prevState[categoryIndex] === faqIndex
          ? null
          : faqIndex;
      return { ...prevState, [categoryIndex]: isActive };
    });
  };

  return (
    <section className={styles.faqSection}>
      <h2 className={styles.sectionTitle}>Frequently Asked Questions</h2>
      <div className={styles.faqContainer}>
        {faqData.map((category, categoryIndex) => (
          <div key={categoryIndex} className={styles.category}>
            <h3 className={styles.categoryTitle}>{category.category}</h3>
            {category.faqs.map((faq, faqIndex) => (
              <div
                key={faqIndex}
                className={`${styles.faqItem} ${
                  activeIndex[categoryIndex] === faqIndex ? styles.active : ""
                }`}
              >
                <div
                  className={styles.question}
                  onClick={() => toggleFAQ(categoryIndex, faqIndex)}
                >
                  <h4>{faq.question}</h4>
                  {activeIndex[categoryIndex] === faqIndex ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </div>
                <div
                  className={styles.answer}
                  style={{
                    maxHeight:
                      activeIndex[categoryIndex] === faqIndex
                        ? "500px"
                        : "0px",
                    transition: "max-height 0.3s ease",
                  }}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Footer />
    </section>
  );
};

export default FAQs;
