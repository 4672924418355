// src/pages/MeetTheBaker.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/MeetTheBaker/HeroSection';
import AboutSection from '../components/MeetTheBaker/AboutSection';
import JourneyTimeline from '../components/MeetTheBaker/JourneyTimeline';
import SocialMediaSection from '../components/MeetTheBaker/SocialMediaSection';
import SEO from '../components/MeetTheBaker/SEO'; // Import the SEO component
import '../styles/MeetTheBaker/MeetTheBaker.css';
import Footer from '../components/Footer';
const MeetTheBaker = () => {
  return (
    <main className="meet-the-baker-page">
            <Helmet>
        <title>Meet Shawn | Founder of Dey-Dey Bakes</title>
        <meta 
          name="description" 
          content="Get to know Shawn, the passionate founder of Dey-Dey Bakes. Learn about his journey, passion for baking, and the story behind our delicious creations." 
        />
        <meta name="keywords" content="Meet the Baker, Shawn Dey, Dey-Dey Bakes Founder, Bakery Founder, Baking Passion, Custom Cakes New York" />
        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Meet Shawn | Founder of Dey-Dey Bakes" />
        <meta property="og:description" content="Discover the story of Shawn, the heart behind Dey-Dey Bakes, and his dedication to crafting exquisite cakes and pastries." />
        <meta property="og:image" content="https://www.deydeybakes.com/assets/images/shawn-selfie.jpg" />
        <meta property="og:url" content="https://www.deydeybakes.com/meetthebaker" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Meet Shawn | Founder of Dey-Dey Bakes" />
        <meta name="twitter:description" content="Get to know Shawn, the passionate founder of Dey-Dey Bakes. Learn about his journey, passion for baking, and the story behind our delicious creations." />
        <meta name="twitter:image" content="https://www.deydeybakes.com/assets/images/shawn-selfie.jpg" />
      </Helmet>
      <SEO />
      <HeroSection />
      <AboutSection />
      <JourneyTimeline />
      <SocialMediaSection />
      <Footer />

    </main>
  );
};

export default MeetTheBaker;