// src/data/Products.js

import eightfive from '../assets/images/products/85_8inch.jpg';
import happybirthday_8inch from '../assets/images/products/happybirthday_8inch.jpg';
import surgery_6inch from '../assets/images/products/surgery_6inch.jpg';
import pecanPie from '../assets/images/products/Pecanpie.jpg';
import pumpkinpie from '../assets/images/products/pumpkinpie.jpg';
import pumpkinspice from '../assets/images/products/pumpkinspicecupcake.jpg';
import cupcakesimg from '../assets/images/cupcakesimg.png';

export const cakes = [
  {
    id: 1,
    category: 'Cakes',
    name: '6" Three Layers Cake',
    description: 'Delicious three-layered cake (6") with your choice of flavors, fillings, and buttercream.',
    sizes: [
      {
        id: 1,
        size: '6" Three Layers (12 servings)',
        price: 90,
      },
    ],
    flavors: [
      'Berry Wine',
      'Carrot',
      'Chocolate',
      'Lemon',
      'Pink Champagne',
      'Red Velvet',
      'Strawberry',
      'Strawberry Shortcake',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    occasions: ['Birthday', 'Wedding', 'Anniversary', 'Holiday'],
    bestSeller: true,
    image: surgery_6inch,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false,
  },
  {
    id: 2,
    category: 'Cakes',
    name: '8" Three Layers Cake',
    description: 'Delicious three-layered cake (8") with your choice of flavors. Select from an array of fillings.',
    sizes: [
      {
        id: 2,
        size: '8" Three Layers (24 servings)',
        price: 120,
      },
    ],
    flavors: [
      'Berry Wine',
      'Carrot',
      'Chocolate',
      'Lemon',
      'Pink Champagne',
      'Red Velvet',
      'Strawberry',
      'Strawberry Shortcake',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    occasions: ['Birthday', 'Wedding'],
    bestSeller: true,
    image: happybirthday_8inch,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },
  {
    id: 3,
    category: 'Cakes',
    name: '10" Three Layers Cake',
    description: 'Delicious three-layered cake (10") with your choice of flavors. Select from an array of fillings.',
    sizes: [
      {
        id: 3,
        size: '10" Three Layers (24 servings)',
        price: 180,
      },
    ],
    flavors: [
      'Berry Wine',
      'Carrot',
      'Chocolate',
      'Lemon',
      'Pink Champagne',
      'Red Velvet',
      'Strawberry',
      'Strawberry Shortcake',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    occasions: ['Birthday', 'Wedding'],
    bestSeller: true,
    image: happybirthday_8inch,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },
  {
    id: 4,
    category: 'Cakes',
    name: '6" Vegan Cakes',
    description: 'Delicious three-layered vegan cake (6") with your choice of flavors. Select from an array of fillings.',
    sizes: [
      {
        id: 4,
        size: '6" Three Layers (12 servings)',
        price: 90,
      },
    ],
    flavors: [
      'Vanilla (Vegan available)',
      'Vanilla Funfetti (Vegan available)',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    occasions: ['Birthday', 'Wedding'],
    bestSeller: true,
    image: surgery_6inch,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },
  {
    id: 5,
    category: 'Cakes',
    name: '8" Vegan Cakes',
    description: 'Delicious three-layered vegan cake (8") with your choice of flavors. Select from an array of fillings.',
    sizes: [
      {
        id: 5,
        size: '8" Three Layers (24 servings)',
        price: 120,
      },
    ],
    flavors: [
      'Vanilla (Vegan available)',
      'Vanilla Funfetti (Vegan available)',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    occasions: ['Birthday', 'Wedding'],
    bestSeller: false,
    image: eightfive,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },
  // ... other cakes
];

export const cupcakes = [
  {
    id: 6,
    category: 'Cupcakes',
    name: 'Cupcake Dozen',
    description: 'A dozen of our finest cupcakes in your preferred flavor. Select from an array of fillings.',
    flavors: [
      'Berry Wine',
      'Carrot',
      'Chocolate',
      'Flourless Chocolate',
      'Lemon',
      'Pink Champagne',
      'Red Velvet',
      'Strawberry',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: true,
    image: cupcakesimg,
    price: 36,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },

  {
    id: 7,
    category: 'Cupcakes',
    name: 'Cupcake Half-Dozen',
    description: 'A half-dozen of our finest cupcakes in your preferred flavor. Select from an array of fillings.',
    flavors: [
      'Berry Wine',
      'Carrot',
      'Chocolate',
      'Flourless Chocolate',
      'Lemon',
      'Pink Champagne',
      'Red Velvet',
      'Strawberry',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: true,
    image: cupcakesimg,
    price: 25,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },
  {
    id: 8,
    category: 'Cupcakes',
    name: 'Vegan Cupcake Dozen',
    description: 'A dozen of our finest vegan cupcakes in your preferred flavor.',
    flavors: [
      'Vanilla (Vegan available)',
      'Vanilla Funfetti (Vegan available)',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: false,
    image: cupcakesimg,
    price: 36,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },
  {
    id: 9,
    category: 'Cupcakes',
    name: 'Vegan Cupcake Half-Dozen',
    description: 'A half-dozen of our finest vegan cupcakes in your preferred flavor.',
    flavors: [
      'Vanilla (Vegan available)',
      'Vanilla Funfetti (Vegan available)',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: false,
    image: cupcakesimg,
    price: 25,
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // New property
  },
  {
    id: 13,
    category: 'Cupcakes',
    name: 'Mini Cupcake Dozen',
    description: 'A dozen of our delightful mini cupcakes in your preferred flavor.',
    flavors: [
      'Berry Wine',
      'Carrot',
      'Chocolate',
      'Flourless Chocolate',
      'Lemon',
      'Pink Champagne',
      'Red Velvet',
      'Strawberry',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: false,
    image: cupcakesimg, // Use an appropriate image
    price: 30, // Adjust price as needed
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false,
  },
  {
    id: 14,
    category: 'Cupcakes',
    name: 'Mini Cupcake Half-Dozen',
    description: 'A half-dozen of our delightful mini cupcakes in your preferred flavor.',
    flavors: [
      'Berry Wine',
      'Carrot',
      'Chocolate',
      'Flourless Chocolate',
      'Lemon',
      'Pink Champagne',
      'Red Velvet',
      'Strawberry',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: false,
    image: cupcakesimg, // Use an appropriate image
    price: 20, // Adjust price as needed
    ingredients: 'Flour, sugar, eggs, butter, vanilla extract',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false,
  },
  {
    id: 15,
    category: 'Cupcakes',
    name: 'Vegan Mini Cupcake Dozen',
    description: 'A dozen of our delightful vegan mini cupcakes in your preferred flavor.',
    flavors: [
      'Vanilla (Vegan available)',
      'Vanilla Funfetti (Vegan available)',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: false,
    image: cupcakesimg, // Use an appropriate image
    price: 30, // Adjust price as needed
    ingredients: 'Flour, sugar, vegan butter, vanilla extract',
    allergens: ['Gluten'],
    quoteOnly: false,
  },
  {
    id: 16,
    category: 'Cupcakes',
    name: 'Vegan Mini Cupcake Half-Dozen',
    description: 'A half-dozen of our delightful vegan mini cupcakes in your preferred flavor.',
    flavors: [
      'Vanilla (Vegan available)',
      'Vanilla Funfetti (Vegan available)',
    ],
    fillings: [
      'None',
      'Ganache',
      'Strawberry Compote',
      'Lemon Curd',
      'Raspberry Jam',
    ],
    buttercreamFlavors: [
      'Vanilla',
      'Coffee',
      'Lemon',
      'Raspberry',
      'Strawberry',
      'Grape Crush',
      'Sour Cherry',
    ],
    hasPlaqueOption: true,
    hasFondantOption: true,
    customizable: true,
    bestSeller: false,
    image: cupcakesimg, // Use an appropriate image
    price: 20, // Adjust price as needed
    ingredients: 'Flour, sugar, vegan butter, vanilla extract',
    allergens: ['Gluten'],
    quoteOnly: false,
  },
  // ... other cupcakes
];

export const pies = [
  {
    id: 7,
    category: 'Pies',
    name: '9" Pies',
    description: 'Choose from our selection of homemade pies each with their own unique design and flavor.',
    customizable: false,
    occasions: ['Birthday', 'Holiday'],
    flavors: [
      'Apple',
      'Blueberry',
      'Cheesecake',
      'Banana Cream',
      'Key Lime',
      'Lemon Meringue',
      'Peach',
      'Pecan',
      'Sweet Potato',
    ],
    bestSeller: false,
    image: pecanPie,
    price: 30, // Corrected: per pie
    ingredients: 'Flour, sugar, eggs, butter, pumpkin puree, spices',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // Not quote-only
  },
  {
    id: 15,
    category: 'Pies',
    name: 'Mini Pies Dozen',
    description: 'A dozen of our homemade mini pies, perfect for any occasion.',
    flavors: [
      'Apple',
      'Blueberry',
      'Peach',
      'Pecan',
      'Sweet Potato',
    ],
    customizable: false,
    bestSeller: false,
    image: pecanPie, // Use an appropriate image
    price: 100, // Adjust price as needed
    ingredients: 'Flour, sugar, butter, fresh fruits',
    allergens: ['Gluten', 'Dairy'],
    quoteOnly: false,
  },
  {
    id: 16,
    category: 'Pies',
    name: 'Mini Pies Half-Dozen',
    description: 'A dozen of our homemade mini pies, perfect for any occasion.',
    flavors: [
      'Apple',
      'Blueberry',
      'Peach',
      'Pecan',
      'Sweet Potato',
    ],
    customizable: false,
    bestSeller: false,
    image: pecanPie, // Use an appropriate image
    price: 50, // Adjust price as needed
    ingredients: 'Flour, sugar, butter, fresh fruits',
    allergens: ['Gluten', 'Dairy'],
    quoteOnly: false,
  },
];

export const seasonal = [
  {
    id: 11,
    category: 'Seasonal',
    name: 'Pumpkin Spice Cupcake',
    description: 'A limited-time seasonal flavor with pumpkin and spices.',
    flavors: ['Pumpkin Spice'],
    customizable: false,
    image: pumpkinspice,
    price: 36, // per cupcake
    ingredients: 'Flour, sugar, eggs, butter, pumpkin puree, spices',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // Assuming seasonal items can be added to cart
  },
  {
    id: 12, // Changed from duplicate id:8 to unique id:12
    category: 'Seasonal',
    name: 'Pumpkin Pie Deluxe',
    description: 'An extra-large pumpkin pie with special spices.',
    customizable: false,
    occasions: ['Birthday', 'Wedding'],
    flavors: [
      'Pumpkin',
      'Sweet Potato',
    ],
    bestSeller: true,
    image: pumpkinpie,
    price: 30, // per deluxe pie
    ingredients: 'Flour, sugar, eggs, butter, pumpkin puree, spices, vanilla',
    allergens: ['Gluten', 'Eggs', 'Dairy'],
    quoteOnly: false, // Not quote-only
  },
  // ... other seasonal products
];