// src/pages/THCPage.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import THCHeroSection from '../components/THC/THCHeroSection';
import THCAboutSection from '../components/THC/THCAboutSection';
import THCProductList from '../components/THC/THCProductList';
import AgeVerificationModal from '../components/THC/AgeVerificationModal';
import ProductSEO from '../components/THC/ProductSEO';
import '../styles/THC/THCPage.css';
import Footer from '../components/Footer';

const THCPage = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const verificationStatus = localStorage.getItem('isVerified');
    if (!verificationStatus) {
      setShowModal(true);
    } else {
      setIsVerified(true);
    }
  }, []);

  const handleVerification = (status) => {
    if (status) {
      localStorage.setItem('isVerified', 'true');
      setIsVerified(true);
      setShowModal(false);
    } else {
      window.location.href = '/'; // Redirect to home if not verified
    }
  };

  return (
    <main className="thc-page">
      <Helmet>
        <title>THC Edibles | Dey-Dey Bakes</title>
        <meta 
          name="description" 
          content="Explore our range of THC-infused edible cakes, cupcakes, and pies. Enjoy delicious desserts enhanced with THC for a unique experience." 
        />
        <meta name="keywords" content="THC Edibles, THC Cakes, THC Cupcakes, THC Pies, Cannabis Bakery, THC Desserts, Buy THC Edibles" />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="THC Edibles | Dey-Dey Bakes" />
        <meta property="og:description" content="Discover our THC-infused edible cakes, cupcakes, and pies. Perfect for those seeking delicious desserts with a THC twist." />
        <meta property="og:image" content="https://www.deydeybakes.com/assets/images/thc/og-thc-edibles.jpg" />
        <meta property="og:url" content="https://www.deydeybakes.com/thc-edibles" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="THC Edibles | Dey-Dey Bakes" />
        <meta name="twitter:description" content="Enjoy our delicious THC-infused cakes, cupcakes, and pies. Crafted for a unique and delightful experience." />
        <meta name="twitter:image" content="https://www.deydeybakes.com/assets/images/thc/twitter-thc-edibles.jpg" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.deydeybakes.com/thc-edibles" />
      </Helmet>

      {showModal && <AgeVerificationModal onVerify={handleVerification} />}

      {isVerified && (
        <>
          <THCHeroSection />
          <THCAboutSection />
          <THCProductList />
          <ProductSEO /> {/* Add this line */}
          <Footer />
        </>
      )}
    </main>
  );
};

export default THCPage;