// src/components/MeetTheBaker/HeroSection.js
import React from 'react';
import '../../styles/MeetTheBaker/HeroSection.css';
import backgroundImage from '../../assets/images/meetthebakerbg.png';
import shawnSelfie from '../../assets/images/DeySelfie.jpg';

const HeroSection = () => {
  return (
    <header
      className="hero-section-baker"
      style={{ 
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        maxHeight: '100vh'
      }}
    >
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <div className="baker-image-wrapper">
          <img src={shawnSelfie} alt="Shawn, the Founder of Dey-Dey Bakes" className="baker-image" loading="lazy" />
        </div>
        <h1 className="hero-title">Meet Shawn, Founder of Dey-Dey Bakes</h1>
        <nav className="hero-nav">
          <a href="#about" className="hero-button">About Shawn</a>
          <a href="#journey" className="hero-button">Shawn's Journey</a>
        </nav>
      </div>
    </header>
  );
};

export default HeroSection;